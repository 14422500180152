import React, { useContext } from "react";
import SEO from "../components/seo";
import CustomAuth from "../components/custom-auth";
import { UserContext } from "../provider/usercontextprovider";
import { UserTenantProvider } from "../provider/usertenantprovider";
import Appointment from "../components/appointment"

const ProtectedPage = () => {
  const [userContext, setUserContext] = useContext(UserContext);
  const isUser =
    userContext.username &&
    userContext.signInUserSession.accessToken.payload["cognito:groups"].includes("User");

  return (
    <CustomAuth>
      <SEO title="Appointment" />
      <UserTenantProvider>
        {isUser ? <Appointment /> : <p>Please sign in.</p> }
      </UserTenantProvider>
    </CustomAuth>
  );
}
export default ProtectedPage;
