import React from "react";
import * as buttonStyles from "./button.module.css";
import { Link } from "gatsby";

const Appointment = () => {
  return (<>
      <p style={{textAlign: "center"}}>Contact us for issues relating to accessing/managing your appointment</p>
      <p style={{textAlign: "center"}}>Our hours of operation are 8:30am-5:00pm Monday to Friday (EST).</p>
      <p style={{textAlign: "center"}}>The toll-free phone number is 1-833-324-2272.</p>
      <div style={{justifyContent: "center", display: "flex"}}>
        <Link className={buttonStyles.button} to="https://outlook.office365.com/owa/calendar/DHSObservationTest@parsons365.onmicrosoft.com/bookings/">
          Book an appointment.
        </Link>
      </div>
  </>)
}

export default Appointment